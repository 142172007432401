import http from '@/http.js'
import qs from 'query-string'

export class AuthService {
  static login (data) {
    return http.get('/profile?' + qs.stringify({
      api_user: data.api_user,
      api_pass: data.api_pass
    }))
  }

  static loginSmartschool (data) {
    return http.post('/oauth/verify', qs.stringify({
      provider: 'smartschool',
      redirect_uri: process.env.VUE_APP_OAUTH_REDIRECT_URL,
      auth_code: data.auth_code
    }))
  }
  
  static getUser () {
    return http.get('/profile')
  }

  static forgotPassword (data) {
    return http.get('/forgotten_username_or_password?' + qs.stringify({
      email: data.email
    }))
  }
}
