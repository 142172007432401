import { EncryptStorage } from 'encrypt-storage';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash, faUser, faLockOpen, faEnvelope, faHome, faAngleDown, faPowerOff, faFileText, faLineChart, faList, faQuestion, faCheck, faMinusCircle, faAngleRight, faAngleLeft, faTimesCircle, faPlusCircle, faBarsStaggered, faInfo, faPlay, faClock, faBars, faArrowLeft, faArrowRight, faFlagCheckered, faHeadphones, faGlobe, faMedkit, faComment, faSmile, faChartLine } from '@fortawesome/free-solid-svg-icons'

const key = 'kURz2KkrPZSaoeAGgrIt';

export const encryptStorage = new EncryptStorage(key);

//Font awesome icons
library.add(faEye, faEyeSlash, faUser, faLockOpen, faEnvelope, faHome, faAngleDown, faPowerOff, faFileText, faLineChart, faList, faQuestion, faCheck, faMinusCircle, faAngleRight, faAngleLeft, faTimesCircle, faPlusCircle, faBarsStaggered, faInfo, faPlay, faClock, faBars, faArrowLeft, faArrowRight, faFlagCheckered, faHeadphones, faGlobe, faMedkit, faComment, faSmile, faChartLine)

export const oauthSmartschoolClientId = "cd493d2ca2b4"
export const oauthSmartschoolClientSecret = "79dd7fc99e15"
export const oauthSmartschoolUrlAuthorize = "https://oauth.smartschool.be/OAuth"