import http from '@/http.js'
import qs from "query-string";

export class LanguageService {
  static getLanguages() {
    return http.get('/languages/languages')
  }

  static translateQuestionLabel (data) {
    return http.get('/languages/translation?' + qs.stringify({
      i18n_source_id: data.getters.currentQuestion.i18n_source_id,
      language_code: data.getters.language_code
    }))
  }

  static translateQuestionExtraInfo (data) {
    return http.get('/languages/translation?' + qs.stringify({
      i18n_source_id: data.getters.currentQuestion.i18n_extra_info_source_id,
      language_code: data.getters.language_code
    }))
  }

  static translateScaleAnswer (data) {
    return http.get('/languages/translation?' + qs.stringify({
      i18n_source_id: data.i18n_source_id,
      language_code: data.language_code
    }))
  }

  static translateChoiceAnswer (data) {
    return http.get('/languages/translation?' + qs.stringify({
      i18n_source_id: data.i18n_source_id,
      language_code: data.language_code
    }))
  }

  static createNewSource (data) {
    return http.post('/languages/source?', qs.stringify( {
      i18n_source_label: data.getters.currentQuestion.label,
      question_id: data.getters.currentQuestion.question_id
    }))
  }
}
