export default {
  "login": {
    "college_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
    "login_as_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden als leerling"])},
    "or_login_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of meld aan met"])},
    "errors": {
      "failed_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login mislukt"])},
      "not_filled_in_correctly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet alles is juist ingevuld"])}
    }
  },
  "forgot_password": {
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "send_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord verzenden"])},
    "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar aanmelden"])},
    "errors": {
      "forgot_password_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten mislukt"])},
      "not_filled_in_correctly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet alles is juist ingevuld"])},
      "not_a_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldig email adres"])}
    }
  },
  "nav": {
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vragenlijsten"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgestelde vragen"])},
    "help_and_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfhulp"])},
    "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn profiel"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])}
  }
}