import { AuthService } from '@/services/auth.service.js'
import { encryptStorage } from '@/constants.js'

const state = {
  user: null,
  firstName: encryptStorage.getItem('appwel-u') ?? null,
  accessCode: encryptStorage.getItem('appwel-p') ?? null,
  error: null
}

const getters = {
  user: state => state.user,
  firstName: state => state.firstName,
  accessCode: state => state.accessCode,
  error: state => state.error
}

const actions = {
  async login ({ commit }, data) {
    return await AuthService.login(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_AUTH', response.data[0])
        commit('SET_USER', response.data[0])
      } else {
        return response.response.data.message
      }
    })
  },
  async loginSmartschool ({ commit }, data) {
    return await AuthService.loginSmartschool(data).then(response => {
      if (response.status === 200) {
        commit('SET_AUTH_SMARTSCHOOL', response.data[0])
      } else {
        commit('SET_ERROR', response.data.message)
        return response.data.message
      }
    })
  },
  async getUser ({ commit }) {
    await AuthService.getUser().then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_USER', response.data[0])
      }
    })
  },
  // eslint-disable-next-line
  async forgotPassword ({ commit }, data) {
    return await AuthService.forgotPassword(data).then(response => {
      if (typeof response.response !== "undefined") {
        return response.response.data.message
      }
    })
  },
  async logout ({ commit }) {
    commit('CLEAR_AUTH')
  },
  removeError ({ commit }) {
    commit('SET_ERROR', '')
  }
}

const mutations = {
  SET_AUTH: (state, payload) => {
    encryptStorage.setItem('appwel-u', payload.data.first_name.toString())
    encryptStorage.setItem('appwel-p', payload.data.access_code.toString())
    state.firstName = payload.data.first_name
    state.accessCode = payload.data.access_code
  },
  SET_AUTH_SMARTSCHOOL: (state, payload) => {
    encryptStorage.setItem('appwel_access_token', payload.data.appwel_access_token.toString())
    encryptStorage.setItem('smartschool_access_token', payload.data.smartschool_access_token.access_token.toString())
    encryptStorage.setItem('smartschool_platform_url', payload.data.smartschool_platform_url.toString())
  },
  SET_USER: (state, payload) => {
    state.user = payload.data
  },
  SET_ERROR: (state, payload) => {
    state.error = payload
  },
  CLEAR_AUTH: (state) => {
    encryptStorage.removeItem('appwel-u')
    encryptStorage.removeItem('appwel-p')
    encryptStorage.removeItem('appwel_access_token')
    encryptStorage.removeItem('smartschool_access_token')
    encryptStorage.removeItem('smartschool_platform_url')
    state.user = null
    state.firstName = null
    state.accessCode = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
