import axios from 'axios'
import store from '@/store'
import { encryptStorage } from '@/constants.js'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    accept: 'application/json'
  }
})

apiClient.interceptors.request.use((config) => {
  if (encryptStorage.getItem('appwel-u') && encryptStorage.getItem('appwel-p')) {
    config.params = { api_user: encryptStorage.getItem('appwel-u'), api_pass: encryptStorage.getItem('appwel-p') }
  }

  return config
})

apiClient.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 403 && !location.href.endsWith('login') && !location.href.endsWith('forgot-password')) {
    store.dispatch('logout')
  }
  return error
})

export default apiClient
