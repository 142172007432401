import http from '@/http.js'
import qs from 'query-string'

export class ResponseService {
  static setResponse (data) {
    return http.post('/responses', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  static getAnswers (data) {
    return http.get('/responses/student_responses_archive/' + data.survey_year_period_id)
  }
}
