import { ResponseService } from '@/services/response.service'

const state = {
  answers: null
}

const getters = {
  answers: state => state.answers
}

const actions = {
  // eslint-disable-next-line
  async setResponse ({ commit }, data) {
    return await ResponseService.setResponse(data).then(response => {
      return response
    })
  },
  async getAnswers ({ commit }, data) {
    await ResponseService.getAnswers(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_ANSWERS', response.data[0])
      }
    })
  },
  clearAnswers({ commit }) {
    commit('CLEAR_ANSWERS')
  }
}

const mutations = {
  SET_ANSWERS: (state, payload) => {
    state.answers = payload.data
  },
  CLEAR_ANSWERS: (state) => {
    state.answers = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
