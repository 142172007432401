import { FaqService } from '@/services/faq.service'

const state = {
  faq: null
}

const getters = {
  faq: state => state.faq
}

const actions = {
  async getFaq ({ commit }) {
    await FaqService.getFaq().then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_FAQ', response)
      }
    })
  }
}

const mutations = {
  SET_FAQ: (state, payload) => {
    state.faq = payload.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
