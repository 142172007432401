import { createApp } from 'vue'
import { encryptStorage } from '@/constants.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Popper from "vue3-popper";

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import './assets/styles/index.scss'

async function main () {
  if (encryptStorage.getItem('appwel-u') && encryptStorage.getItem('appwel-p') && !store.getters.user) {
    await store.dispatch('getUser')
  }
  createApp(App).use(i18n).use(store).use(router).component("Popper", Popper).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
}

main()