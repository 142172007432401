import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    alias: '/',
    meta: { login: true },
    children: [
      {
        path: '/',
        name: 'Surveys',
        component: () => import('@/views/surveys/Surveys'),
        meta: { login: true }
      },
      {
        path: '/vragenlijsten',
        name: 'Surveys',
        component: () => import('@/views/surveys/Surveys'),
        meta: { login: true }
      },
      {
        path: '/vragenlijst',
        name: 'Survey',
        component: () => import('@/views/surveys/Question'),
        meta: { login: true }
      },
      {
        path: '/resultaten',
        name: 'Results',
        component: () => import('@/views/results/Results'),
        meta: { login: true }
      },
      {
        path: '/antwoorden',
        name: 'Answers',
        component: () => import('@/views/answers/Answers'),
        meta: { login: true }
      },
      {
        path: '/profiel',
        name: 'Profile',
        component: () => import('@/views/Profile'),
        meta: { login: true }
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: () => import('@/views/FAQ'),
        meta: { login: true }
      },
      {
        path: '/help',
        name: 'FAQ',
        component: () => import('@/views/Help'),
        meta: { login: true }
      }
    ]
  },
  {
    path: '/oauth/smartschool',
    name: 'SmartschoolAuth',
    component: () => import('@/views/auth/Smartschool'),
    meta: { login: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
    meta: { login: false },
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword'),
    meta: { login: false }
  },
  {
    path: '/niet-geauthoriseerd',
    name: '403',
    component: () => import('@/views/NoAccess.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.login === true && !store.getters.user) {
    return next('/login')
  }

  if (to.meta?.login === false && store.getters.user) {
    return next('/vragenlijsten')
  }

  next()
})

export default router
