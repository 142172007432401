import { createStore } from 'vuex'
import auth from './modules/auth'
import survey from './modules/survey'
import response from './modules/response'
import faq from './modules/faq'
import result from './modules/result'

export default createStore({
  modules: {
    auth,
    survey,
    response,
    faq,
    result
  }
})
