import { SurveyService } from '@/services/survey.service.js'
import { LanguageService } from '@/services/language.service.js'
import { encryptStorage } from '@/constants.js'


const state = {
  surveys: null,
  currentSurveyYearPeriodId: null,
  currentQuestion: null,
  language_code: null,
  i18n_source_id: null,
  answer_completely_disagree: 'Helemaal niet akkoord',
  answer_rather_disagree: 'Eerder niet akkoord',
  answer_neutral: 'Neutraal',
  answer_rather_agree: 'Eerder akkoord',
  answer_completely_agree: 'Helemaal akkoord',
  answer_yes: 'Ja',
  answer_sometimes: 'Soms',
  answer_no: 'Nee',
  answer_completely_not: 'Helemaal niet',
  answer_not: 'Niet',
  answer_sometimes_stage_3: 'Soms',
  answer_yes_stage_3: 'Wel',
  answer_completely_yes: 'Helemaal wel',
  answer_choices: {},
  audio: encryptStorage.getItem('audio') ? true : false
}

const getters = {
  surveys: state => state.surveys,
  currentSurveyYearPeriodId: state => state.currentSurveyYearPeriodId,
  currentQuestion: state => state.currentQuestion,
  language_code: state => state.language_code,
  i18n_source_id: state => state.i18n_source_id,
  answer_completely_disagree: state => state.answer_completely_disagree,
  answer_rather_disagree: state => state.answer_rather_disagree,
  answer_neutral: state => state.answer_neutral,
  answer_rather_agree: state => state.answer_rather_agree,
  answer_completely_agree: state => state.answer_completely_agree,
  answer_yes: state => state.answer_yes,
  answer_sometimes: state => state.answer_sometimes,
  answer_no: state => state.answer_no,
  answer_completely_not: state => state.answer_completely_not,
  answer_not: state => state.answer_not,
  answer_sometimes_stage_3: state => state.answer_sometimes_stage_3,
  answer_yes_stage_3: state => state.answer_yes_stage_3,
  answer_completely_yes: state => state.answer_completely_yes,
  answer_choices: state => state.answer_choices,
  audio: state => state.audio
}

const actions = {
  async getSurveys ({ commit }) {
    await SurveyService.getSurveys().then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_SURVEYS', response.data[0])
      }
    })
  },
  async getNextQuestion ({ commit }, data) {
    await SurveyService.getNextQuestion(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_QUESTION', response.data[0])
      }
    })
  },
  async translateQuestionLabel (data) {
    return await LanguageService.translateQuestionLabel(data).then(response => {
      if (response.data.status && response.data.status == 'No translation available') {
        alert('Van deze vraag is momenteel nog geen vertaling beschikbaar. Excuses voor het ongemak.')
      } else {
        state.currentQuestion.label = response.data.data
      }
      return response
    })
  },
  async translateQuestionExtraInfo (data) {
    return await LanguageService.translateQuestionExtraInfo(data).then(response => {
      if (response.data.data) {
        state.currentQuestion.extra_info = response.data.data
      }
      return response
    })
  },
  async translateScaleAnswer ({ commit }, data) {
    await LanguageService.translateScaleAnswer(data).then(response => {
      if (response.data.data) {
        switch (data.i18n_source_id) {
          case 1:
            commit('SET_ANSWER_COMPLETELY_DISAGREE', response.data.data)
            break
          case 2:
            commit('SET_ANSWER_RATHER_DISAGREE', response.data.data)
            break
          case 3:
            commit('SET_ANSWER_NEUTRAL', response.data.data)
            break
          case 4:
            commit('SET_ANSWER_RATHER_AGREE', response.data.data)
            break
          case 5:
            commit('SET_ANSWER_COMPLETELY_AGREE', response.data.data)
            break
          case 6:
            commit('SET_ANSWER_YES', response.data.data)
            break
          case 7:
            commit('SET_ANSWER_SOMETIMES', response.data.data)
            break
          case 8:
            commit('SET_ANSWER_NO', response.data.data)
            break
          case 9:
            commit('SET_ANSWER_COMPLETELY_NOT', response.data.data)
            break
          case 10:
            commit('SET_ANSWER_NOT', response.data.data)
            break
          case 11:
            commit('SET_ANSWER_SOMETIMES_STAGE_3', response.data.data)
            break
          case 12:
            commit('SET_ANSWER_YES_STAGE_3', response.data.data)
            break
          case 13:
            commit('SET_ANSWER_COMPLETELY_YES', response.data.data)
            break
        }
      }
    })
  },
  async translateChoiceAnswer ({ commit }, data) {
    await LanguageService.translateChoiceAnswer(data).then(response => {
      if (response.data.data) {
        commit('SET_CHOICE_ANSWER', {'i18n_source_id': data.i18n_source_id, 'translation': response.data.data})
      }
    })
  },
  async createNewSource (data) {
    return await LanguageService.createNewSource(data).then(response => {
      return response
    })
  },
  async getQuestionBySequence ({ commit }, data) {
    await SurveyService.getQuestionBySequence(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_QUESTION', response.data[0])
      }
    })
  },
  setSurveyYearPeriodId ({ commit }, data) {
    commit('SET_SURVEY_YEAR_PERIOD_ID', data)
  },
  questionCompleted ({ commit }, data) {
    commit('QUESTION_COMPLETED', data)
  },
  setLanguageCode ({ commit }, data) {
    commit('SET_LANGUAGE_CODE', data)
  },
  set_i18n_source_id ({ commit }, data) {
    commit('SET_i18n_SOURCE_ID', data)
  },
  updateCurrentQuestionWithNewSourceId ({ commit }, data) {
    commit('SET_UPDATEQUESTION', data)
  },
  clearSurveys({ commit }) {
    commit('CLEAR_SURVEYS')
  },
  setAudio ({ commit }, data) {
    commit('SET_AUDIO', data)
  }
}

const mutations = {
  SET_SURVEYS: (state, payload) => {
    state.surveys = payload.data
  },
  SET_QUESTION: (state, payload) => {
    state.currentQuestion = payload.data
  },
  SET_UPDATEQUESTION: (state, payload) => {
    let currentQuestion = state.currentQuestion
    currentQuestion.i18n_source_id = payload
    state.currentQuestion = currentQuestion
  },
  SET_SURVEY_YEAR_PERIOD_ID: (state, payload) => {
    state.currentSurveyYearPeriodId = payload
  },
  SET_LANGUAGE_CODE: (state, payload) => {
    state.language_code = payload
  },
  SET_i18n_SOURCE_ID: (state, payload) => {
    state.i18n_source_id = payload
  },
  SET_ANSWER_COMPLETELY_DISAGREE: (state, payload) => {
    state.answer_completely_disagree = payload
  },
  SET_ANSWER_RATHER_DISAGREE: (state, payload) => {
    state.answer_rather_disagree = payload
  },
  SET_ANSWER_NEUTRAL: (state, payload) => {
    state.answer_neutral = payload
  },
  SET_ANSWER_RATHER_AGREE: (state, payload) => {
    state.answer_rather_agree = payload
  },
  SET_ANSWER_COMPLETELY_AGREE: (state, payload) => {
    state.answer_completely_agree = payload
  },
  SET_ANSWER_YES: (state, payload) => {
    state.answer_yes = payload
  },
  SET_ANSWER_SOMETIMES: (state, payload) => {
    state.answer_sometimes = payload
  },
  SET_ANSWER_NO: (state, payload) => {
    state.answer_no = payload
  },
  SET_ANSWER_COMPLETELY_NOT: (state, payload) => {
    state.answer_completely_not = payload
  },
  SET_ANSWER_NOT: (state, payload) => {
    state.answer_not = payload
  },
  SET_ANSWER_SOMETIMES_STAGE_3: (state, payload) => {
    state.answer_sometimes_stage_3 = payload
  },
  SET_ANSWER_YES_STAGE_3: (state, payload) => {
    state.answer_yes_stage_3 = payload
  },
  SET_ANSWER_COMPLETELY_YES: (state, payload) => {
    state.answer_completely_yes = payload
  },
  SET_CHOICE_ANSWER: (state, payload) => {
    state.answer_choices[payload.i18n_source_id] = payload.translation
  },
  QUESTION_COMPLETED: (state, payload) => {
    const index = state.surveys.indexOf(state.surveys.find(survey => survey.periods[0].survey_year_period_id === payload.survey_year_period_id));
    if (payload.complete) {
      state.surveys[index].periods[0].response_status = "COMPLETE"
    }
    state.surveys[index].periods[0].response_count++
  },
  CLEAR_SURVEYS: (state) => {
    state.surveys = null
    state.currentSurvey = null
    state.currentSurveyYearPeriodId = null
    state.currentQuestion = null
  },
  SET_AUDIO: (state, payload) => {
    state.audio = payload
    if (payload) encryptStorage.setItem('audio', payload)
    else encryptStorage.removeItem('audio')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
